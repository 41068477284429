<template>
    <div v-if="user"> <!-- v-touch:swipe.left="swipeLeftHandler"  v-touch:swipe.right="swipeRightHandler" -->
        
        <div class="container" v-if="user.data">

            <div class="row justify-content-center">

                <!-- Push subscription message -->
                <div class="col card shadow p-3 mb-5 bg-secondary rounded mt-4">

                    <div class="card-body">

                        <div class="row">

                            <div class="col-12">
                                <subscriptions :required-license="1" :current-license="user.subscription" />
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            
        </div>
       
    </div>
</template>
<script>

import Subscriptions from "@/components/billing/Subscriptions";

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

export default {
    name: 'subscription',
    metaInfo: {
      title: 'Manage Subscription',
    },
    components: {
        Subscriptions
    },

    data() {
      return {
      }
    },

    mounted() {
        
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    methods: {

        // swipeLeftHandler(){

        //     if(!this.currentWeek && !this.$isDesktop()){
        //         this.$router.replace('profile')
        //     }

        // },

        // swipeRightHandler(){

        //     if(!this.currentWeek && !this.$isDesktop()){
        //         this.$router.replace('reflectnow')
        //     }
            
        // }

    }
    // firestore () {
    //   return {
    //     mygroups: db.collection('groups').where('ownerId', '==', this.user.data.uid).orderBy('groupName', "asc")
    //     // groups: db.collection('groups').where('uids', 'array-contains',  this.user.data.uid ).orderBy('groupName', "asc")
    //   }
    // } // 

};
</script>
<style scoped>

</style>
