<template>
<section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="container pt-lg-md">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0">
                    <template>
                        <div class="text-center text-grey-800 font-weight-bold mb-4">
                            Sign in
                        </div>
                        <div class="alert alert-danger" v-if="error_message">
                            <h5 class="text-white font-weight-bold">Oh no....</h5>
                            {{ error_message }}
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(loginUser)">
                                <validation-provider mode="lazy" name="email" rules="required|email" v-slot="{ errors }">
                                    <base-input alternative v-model="email" required class="mb-3" placeholder="Email" :error="errors[0]" addon-left-icon="fad fa-envelope">
                                    </base-input>
                                    <!-- <span class="form-errors">{{ errors[0] }}</span> -->
                                </validation-provider>

                                <validation-provider mode="lazy" name="password" rules="required" v-slot="{ errors }">
                                    <base-input alternative type="password" required v-model="password" placeholder="Password" :error="errors[0]" addon-left-icon="fad fa-lock">
                                    </base-input>
                                </validation-provider>
                                <!-- <base-checkbox>
                                        Remember me
                                    </base-checkbox> -->
                                <div class="text-center">
                                    <base-button native-type="submit" type="primary" class="my-4">Sign In</base-button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </template>

                    <template>
                        <div class="text-muted font-weight-bold text-center mb-3">
                            <small>OR sign in with</small>
                        </div>
                        <div class="btn-wrapper text-center">

                            <base-button class="btn btn-neutral btn-facebook btn-round btn-lg" size="sm" type="neutral" @click.prevent="loginWithFacebook">
                                <icon name="fab fa-facebook-square" size="sm"></icon>
                                Facebook
                            </base-button>

                            <base-button class="btn btn-neutral btn-google-plus btn-round btn-lg" size="sm" type="neutral" @click.prevent="loginWithGoogle">
                                <icon name="fab fa-google" size="sm"></icon>
                                Google
                            </base-button>
                        </div>
                    </template>

                    <modal :show.sync="modals.loggin" gradient="default" modal-classes="modal-default modal-dialog-centered">

                        <div class="py-3 text-center">
                            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                            <h4 class="heading mt-4">We are logging you in!</h4>
                            <p>"The more seriously you take personal &amp; professional reflection, the faster you will develop into the superstar leader your team needs" - Chris Winfield-Blum</p>
                        </div>

                    </modal>

                </card>
                <div class="row mt-3">
                    <div class="col-6">
                        <router-link to="/forgotpassword" class="text-light"><small>Forgot password?</small></router-link>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import {
    mapGetters
} from "vuex";

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate';
import {
    extend
} from 'vee-validate';
import {
    required,
    email
} from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);

import Modal from "@/components/Modal.vue";
export default {
    name: 'login',
    metaInfo: {
        title: 'Login',
    },
    components: {
        Modal,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            email: '',
            password: '',
            error_message: '',
            modals: {
                loggin: false
            }
        }
    },
    // mounted() {

    // },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },
    methods: {

        loginUser() {

            this.modals.loggin = true
            let loginEmail = this.email.trim()
            let loginPassword = this.password.trim()

            firebase.auth().signInWithEmailAndPassword(loginEmail, loginPassword).then(
                (user) => {

                    this.$analytics.logEvent('login', {
                        'provider': 'email'
                    });

                    this.$store.commit("SET_LOGGED_IN", true);
                    this.modals.loggin = false
                    setTimeout(this.redirect, 500)
                },
                (err) => {
                    this.modals.loggin = false
                    this.error_message = err.message
                }
            );
        },

        loginWithFacebook() {

            const vm = this

            var provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function (result) {

                // This gives you a Google Access Token. You can use it to access the Google API.
                if (result.credential) {
                    // This gives you a Google Access Token.
                    var token = result.credential.accessToken;
                }
                var user = result.user;

                // console.log(result)
                // console.log(user)
                // console.log(user.uid)

                // Let us confirm if the user exists or not
                var docRef = db.collection("users").doc(user.uid);
                docRef.get().then(function (doc) {
                    if (doc.exists) {

                        vm.$analytics.logEvent('login', {
                            'provider': 'facebook'
                        });
                        vm.$store.commit("SET_LOGGED_IN", true);

                        setTimeout(vm.redirect, 500)

                    } else {

                        let ourDate = new Date();
                        let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate)

                        var nameSplit = user.displayName.split(' ');
                        var firstName = nameSplit[0]
                        var lastName = nameSplit[nameSplit.length - 1]

                        const userMap = {
                            firstName: firstName,
                            lastName: lastName,
                            fullName: user.displayName,
                            email: user.email,
                            token: token,
                            uid: user.uid,
                            creationTime: timeStamp,
                            lastSignInTime: timeStamp,
                            emailVerified: user.emailVerified,
                            isAnonymous: user.isAnonymous,
                            photoURL: user.photoURL,
                            providerId: 'facebook.com',
                            location: null,
                            dateOfBirth: null,
                            biography: null,
                            statusText: null,
                            reflectionStats: {
                                reflections_total: 0,
                                reflections_meeting: 0,
                                reflections_task: 0,
                                reflections_project: 0,
                                reflections_training: 0,
                                reflections_game: 0,
                                reflections_wellness: 0,
                                reflections_appointment: 0,
                                reflections_learning: 0,
                                reflections_personal: 0,
                                reflections_general: 0,
                                reflections_event: 0,
                                reflections_sleep: 0,
                                ratings_total: 0,
                                ratings_meeting: 0,
                                ratings_task: 0,
                                ratings_project: 0,
                                ratings_training: 0,
                                ratings_game: 0,
                                ratings_wellness: 0,
                                ratings_appointment: 0,
                                ratings_learning: 0,
                                ratings_personal: 0,
                                ratings_general: 0,
                                ratings_event: 0,
                                ratings_sleep: 0
                            },
                            reflectionsWeekly: []
                        };

                        db.collection("users").doc(user.uid).set(userMap)
                            .then(function (docRef) {

                                vm.$analytics.logEvent('registered', {
                                    'source': 'facebook'
                                });
                                vm.$analytics.logEvent('login', {
                                    'provider': 'facebook'
                                });

                                vm.$store.commit("SET_LOGGED_IN", true);
                                vm.$store.commit("SET_USER", userMap);

                                setTimeout(vm.redirect, 500);

                            })
                            .catch(function (error) {
                                // console.error("Error adding document: ", error);
                            });

                    }

                }).catch(function (error) {
                    // console.log("Error getting document:", error);
                });

                // ...
            }).catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                vm.error_message = errorMessage
                // ...
            });

        },

        loginWithGoogle() {

            const vm = this

            var provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function (result) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                // console.log(token)
                // console.log(user)
                // console.log(user.uid)

                // Let us confirm if the user exists or not
                var docRef = db.collection("users").doc(user.uid);
                docRef.get().then(function (doc) {
                    if (doc.exists) {

                        vm.$analytics.logEvent('login', {
                            'provider': 'gooogle'
                        });
                        vm.$store.commit("SET_LOGGED_IN", true);

                        setTimeout(vm.redirect, 500)

                    } else {

                        let ourDate = new Date();
                        let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate)

                        var nameSplit = user.displayName.split(' ');
                        var firstName = nameSplit[0]
                        var lastName = nameSplit[nameSplit.length - 1]

                        const userMap = {
                            firstName: firstName,
                            lastName: lastName,
                            fullName: user.displayName,
                            email: user.email,
                            token: token,
                            uid: user.uid,
                            creationTime: timeStamp,
                            lastSignInTime: timeStamp,
                            emailVerified: user.emailVerified,
                            isAnonymous: user.isAnonymous,
                            photoURL: user.photoURL,
                            providerId: 'google.com',
                            location: null,
                            dateOfBirth: null,
                            biography: null,
                            statusText: null,
                            reflectionStats: {
                                reflections_total: 0,
                                reflections_meeting: 0,
                                reflections_task: 0,
                                reflections_project: 0,
                                reflections_training: 0,
                                reflections_game: 0,
                                reflections_wellness: 0,
                                reflections_appointment: 0,
                                reflections_learning: 0,
                                reflections_personal: 0,
                                reflections_general: 0,
                                reflections_event: 0,
                                reflections_sleep: 0,
                                ratings_total: 0,
                                ratings_meeting: 0,
                                ratings_task: 0,
                                ratings_project: 0,
                                ratings_training: 0,
                                ratings_game: 0,
                                ratings_wellness: 0,
                                ratings_appointment: 0,
                                ratings_learning: 0,
                                ratings_personal: 0,
                                ratings_general: 0,
                                ratings_event: 0,
                                ratings_sleep: 0
                            },
                            reflectionsWeekly: []
                        };

                        db.collection("users").doc(user.uid).set(userMap)
                            .then(function (docRef) {

                                vm.$analytics.logEvent('registered', {
                                    'source': 'google'
                                });
                                vm.$analytics.logEvent('login', {
                                    'provider': 'gooogle'
                                });

                                vm.$store.commit("SET_LOGGED_IN", true);
                                vm.$store.commit("SET_USER", userMap);

                                setTimeout(vm.redirect, 500);

                            })
                            .catch(function (error) {
                                // console.error("Error adding document: ", error);
                            });

                    }

                }).catch(function (error) {
                    // console.log("Error getting document:", error);

                });

                // ...
            }).catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                vm.error_message = errorMessage

                // ...
            });

        },

        redirect() {
            this.$router.replace('reflectnow')
        }

    }
}
</script>

<style>
</style>
