<template>
<section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="container pt-lg-md">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0">
                    <template v-if="!success">
                        <div class="text-center text-muted mb-4">
                            <small>Reset password</small>
                        </div>
                        <div class="alert alert-danger" v-if="error_message">
                            <h5 class="text-white font-weight-bold">Oh no....</h5>
                            {{ error_message }}
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(loginUser)">
                                <validation-provider mode="lazy" name="email" rules="required|email" v-slot="{ errors }">
                                    <base-input alternative v-model="email" required class="mb-3" placeholder="Email" :error="errors[0]" addon-left-icon="fad fa-envelope">
                                    </base-input>
                                    <!-- <span class="form-errors">{{ errors[0] }}</span> -->
                                </validation-provider>

                                <div class="text-center">
                                    <base-button native-type="submit" type="primary" class="my-4">Reset Password</base-button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </template>
                    <p class="text-center" v-if="success">
                        Please check your email for your password reset instructions!
                    </p>

                    <!-- <template>
                            <div class="text-muted text-center mb-3">
                                <small>OR sign in with</small>
                            </div>
                            <div class="btn-wrapper text-center">
                                <base-button class="socialmedia-facebook" size="sm" type="neutral">
                                    <icon name="fab fa-facebook-square"  size="sm"></icon>
                                    Facebook
                                </base-button>

                                <base-button class="socialmedia-google" size="sm" type="neutral">
                                    <icon name="fab fa-google" size="sm"></icon>
                                    Google
                                </base-button>
                            </div>
                        </template> -->

                </card>
                <div class="row mt-3">
                    <div class="col-6">
                        <router-link to="/login" class="text-light"><small>Login</small></router-link>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import firebase from 'firebase/app'
import {
    mapGetters
} from "vuex";

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate';
import {
    extend
} from 'vee-validate';
import {
    required,
    email
} from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);

export default {
    name: 'forgotpassword',
    metaInfo: {
        title: 'Forgotten Password',
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            email: '',
            error_message: '',
            success: false
        }
    },

    methods: {

        loginUser() {

            let loginEmail = this.email.trim()
            firebase.auth().sendPasswordResetEmail(loginEmail).then(
                (user) => {

                    this.$analytics.logEvent('password_reset');
                    this.success = true

                },
                (err) => {
                    this.error_message = err.message
                }
            );
        },
        redirect() {
            this.$router.replace('reflectnow')
        }

    }
}
</script>

<style>
</style>
