<template>
    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-default">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <registration-form ref="regForm" redirect-to="reflectnow" register-label="Sign up to start reflecting" form-type="secondary" shadow></registration-form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

  import RegistrationForm from "@/components/auth/Form";

  import store from '@/store.js'
  import { mapGetters } from "vuex";
  
  export default {
    name: 'signUp',
    metaInfo: {
      title: 'Register',
    },
    components: {
        RegistrationForm
    },
    data() {
      return {}
    },
    mounted() {
      this.$watch(
        "$refs.regForm.regStatus", (new_value, old_value) => {
          //execute your code here
          if(new_value === 'success'){
              this.$router.replace('welcome')
          }
        }
      );
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },
    methods: {

        // Receive Registration Success message from form
        receiveRegStatus(uid){
          
            this.$router.replace('welcome')

        }

    }

  }
</script>
<style>
</style>
