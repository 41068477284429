<template>
    <div>

        <div v-if="processed && valid">

            <!-- HERO INTRO -->
            <div class="position-relative mb-4">
                <!-- shape Hero -->
                <section class="section-shaped my-0">
                    <div class="shape shape-style-1 bg-gradient-default shape-skew">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="container shape-container d-flex">
                        <div class="col px-0">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h1 class="display-3 text-white">Join us!</h1>
                                    <p class="lead text-white">
                                        The owner of the {{ group.groupName }} group @ My Reflections would like you to join their group!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 1st Hero Variation -->
            </div>

            <section class="section section-lg pt-lg-0 mt--300">
                <div class="container">

                    <card class="border-0" hover shadow body-classes="py-5">



                        <p class="text-default">
                            This group is owned by <span class="font-weight-bold">{{ groupOwner }}</span> with the mission of <span class="font-weight-bold">"{{ group.groupMission }}"</span>.
                            There is currently <span class="badge badge-default">{{ group.members.length }}</span> members and <span class="badge badge-default">{{ group.invitations.length }}</span> invites sent for this group!
                        </p>                        

                        <div class="alert alert-secondary pt-4" v-if="group.groupType === 'open'">

                            <span class="fa-stack text-default">
                                <i class="fas fa-square fa-stack-2x"></i>
                                <i class="fad fa-users fa-stack-1x fa-inverse"></i>
                            </span>

                            <span class="font-weight-bold text-default">IMPORTANT: Please note that this group is {{ group.groupType }}</span>

                            <p class="ml-2 mt-4">
                                Open groups allow all members to see each others reflections and group content.
                                Please only accept this invitation if you are comfortable with this!
                            </p>

                            <p class="m-2">
                                The below outlines the information that is shared and with who
                            </p>

                            <ul class="fa-ul">
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Reflection data tagged with "group tags" will be shared with the group owner</li>
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Reflection data tagged with "group tags" will be shared with group leaders</li>
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Reflection data tagged with "group tags" will be shared with other group members</li>
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Comments &amp; discussions on "group available content" that I submit will be shared with all group members</li>
                            </ul>

                        </div>

                        <div class="alert alert-dark pt-4" v-else>

                            <span class="fa-stack text-default">
                                <i class="fas fa-square fa-stack-2x"></i>
                                <i class="fad fa-chart-network fa-stack-1x fa-inverse"></i>
                            </span>

                            <span class="font-weight-bold text-default">IMPORTANT: Please note that this group is {{ group.groupType }}</span>

                            <p class="m-2">
                                Hierarchical (or closed) groups limits the sharing of data between yourself and the groups owner and nominated leaders.
                                Group content will still be available but your reflection data will not be seen by other members.
                            </p>

                            <p class="m-2">
                                The below outlines the information that is shared and with who
                            </p>

                            <ul class="fa-ul">
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Reflection data tagged with "group tags" will be shared with the group owner</li>
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Reflection data tagged with "group tags" will be shared with group leaders</li>
                                <li><span class="fa-li"><i class="fal fa-check"></i></span> Comments &amp; discussions on "group available content" that I submit will be shared with all group members</li>
                            </ul>

                        </div>

                        <div class="text-default">

                            <div class="pointer" v-if="!confirmed" @click="confirmed = !confirmed">
                                <ul class="fa-ul ml-4">
                                    <li><span class="fa-li"><i class="fal fa-circle"></i></span> I consent to my data being visible &amp; shared within this group based on the above outline</li>
                                </ul>
                            </div>
                            <div class="pointer" v-else @click="confirmed = !confirmed">
                                <ul class="fa-ul ml-4">
                                    <li><span class="fa-li"><i class="fad fa-check-circle"></i></span> I consent to my data being visible &amp; shared within this group based on the above outline</li>
                                </ul>
                            </div>

                        </div>

                        <div v-show="user.loggedIn && !joining && !message">

                            <base-button role="button" type="default" class="disabled" size="lg" v-if="!confirmed">
                                Join this group
                            </base-button>
                            <base-button role="button" type="default" size="lg" v-else @click="joinGroup">
                                Join this group
                            </base-button>

                        </div>

                        <div v-show="user.loggedIn && message">

                            <div class="alert alert-danger">
                                <i class="fad fa-exclamation-triangle"></i>  {{ message }}
                            </div>

                        </div>

                        <div v-show="user.loggedIn && joining">

                            <i class="fad fa-spinner-third fa-spin"></i> Joining the group, please hold...

                        </div>

                        <div v-show="!user.loggedIn">

                            <p class="text-center">
                                <base-button type="default" @click="displayRego = true" v-if="!displayRego" class="mt-2">
                                    Register Now
                                </base-button>
                            </p>

                            <div class="mt-2 text-default" v-show="displayRego">
                                <registration-form ref="regForm" redirect-to="reflectnow" register-label="Sign up to get started!" form-type="white"></registration-form>
                            </div>

                        </div>

                    </card>

                </div>
            </section>

        </div>


        <div v-if="processed && !valid">

            <section class="section section-shaped my-0 overflow-hidden">
                <div class="shape shape-style-3 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container pt-lg pb-300">
                    <div class="row text-center justify-content-center mt-4">
                        <div class="col-lg-10 mt-4">
                            <h2 class="display-3 text-white">{{ message }}</h2>
                        </div>
                    </div>
                </div>
            </section>

        </div>


        <div v-if="!processed">

            <section class="section section-shaped my-0 overflow-hidden">
                <div class="shape shape-style-3 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container pt-lg pb-300">
                    <div class="row text-center justify-content-center mt-4">
                        <div class="col-lg-10 mt-4">
                            <h2 class="display-3 text-white"><i class="fad fa-spinner-third fa-spin"></i> Verifying your invitation is valid</h2>
                        </div>
                    </div>
                </div>
            </section>

        </div>
       
    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import md5 from "js-md5";

import RegistrationForm from "@/components/auth/Form";

// const currentUser = firebase.auth().currentUser;

export default {
    name: 'invitation',

    components: {
        RegistrationForm
        // Reflection,
        // Comments
    },

    data() {
      return {
          groupId: null,
          groupOwner: null,
          inviteId: null,
          processed: false,
          valid: false,
          joining: false,
          acknowledged: false,
          group: null,
          message: null,
          confirmed: false,
          displayRego: false
      }
    },

    metaInfo: {
      title: 'Your amazing invitation'
    },

    mounted() {

      

    },

    mounted() {

        this.setInviteIds()
    },

    watch: {
        groupId: function(){
            this.startVerification()
        }
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },


    methods: {

        setGroupOwner(){

            let members = this.group.members
            members.forEach((member) => {

                if(member.uid === this.group.ownerId){
                    this.groupOwner = member.fullName
                }

            })

        },

        setInviteIds(){
            this.groupId = this.$route.params.guid
            this.inviteId = this.$route.params.key
        },

        startVerification(){

            if(this.groupId && this.inviteId){
                this.confirmInvite()
            }

        },

        confirmInvite(){

            if(!this.groupId && !this.inviteId){
                return
            }

            const vm = this
            const axios = require('axios');

            // Send token to your backend via HTTPS
            var config = {};

            // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/confirmgroupinvite/'+ this.groupId + '/' + this.inviteId
            let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/confirmgroupinvite/'+ this.groupId + '/' + this.inviteId

            // Make a request for a user with a given ID
            axios.get( url , config)
            .then(function (response) {

                // handle success
                vm.group = response.data
                vm.setGroupOwner()
                vm.processed = true
                vm.valid = true
                

            })
            .catch(function (error) {
                // handle error
                // console.log(error.response)
                vm.processed = true
                vm.message = error.response.data.message
                // vm.valid = false
            })


        },

        getProfileURL() {
            let emailHash = md5(this.user.data.email);
            return "https://www.gravatar.com/avatar/" + emailHash + "?s=200&d=retro";
        },

        joinGroup(){

            if(!this.groupId && !this.inviteId){
                return
            }

            const vm = this
            const axios = require('axios');

            vm.joining = true;

            firebase.auth().currentUser.getIdToken(true).then(function(idToken) {

                // Send token to your backend via HTTPS
                var config = {
                    headers: { 'Authorization': "Bearer " + idToken }
                };

                // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/acceptgroupinvite'
                let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/acceptgroupinvite'

                let photoURL = vm.user.data.photoURL
                if(!photoURL){
                    photoURL = vm.getProfileURL()
                }

                // Post my data to the API
                axios.post(url, {

                    groupId: vm.groupId,
                    inviteId: vm.inviteId,
                    firstName: vm.user.data.firstName,
                    lastName: vm.user.data.lastName,
                    fullName: vm.user.data.fullName,
                    photoURL: photoURL,
                    uid: vm.user.data.uid

                }, config).then(function (response) {

                    vm.joining = false;
                    vm.$router.replace('/reflectnow')

                    // looks good console log
                    vm.$toast.open({
                        message: 'You successfully joined the group',
                        type: 'success',
                        // all of other options may go here
                    });
                }).catch(function (error) {

                    vm.joining = false;
                    vm.message = error.response.data.message

                });

            // ...
            }).catch(function(error) {
                // Handle error
                // console.log(error)
            });


        }

    }

};
</script>
<style scoped>

.img-inthought {
    width: 25%; 
    height: 25%;
}

.img-filter {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 35px; 
    height: 35px;
}

.unselected {
    filter: grayscale(100%);
    opacity: 0.3;
}

.row-striped:nth-of-type(odd){
  background-color: #efefef;
  /* border-left: 4px #000000 solid; */
}

.row-striped:nth-of-type(even){
  background-color: #ffffff;
  /* border-left: 4px #efefef solid; */
}

.row-striped {
    padding: 15px 0;
}

</style>
